<template>
    <div class="container-fluid">
        <div class="d-flex align-items-center justify-content-between pb-2 border-bottom mb-3">
            <h2>Systems Status History</h2>
            <b-button-group>
                <b-btn variant="outline-success" :disabled="loading" @click="load"><i class="fas fa-sync-alt fa-fw"></i> Refresh</b-btn>
                <b-btn variant="outline-success" :disabled="loading" @click="showSearchOptions = !showSearchOptions"><i class="fas fa-search fa-fw"></i> Search Options</b-btn>
            </b-button-group>
        </div>

        <div v-if="loading" class="text-center">
            <h3><b-spinner type="grow" variant="success" label="Loading..." /> Loading...</h3>
        </div>
        <b-alert v-else-if="hasErr" variant="danger" :show="hasErr"><i class="fas fa-exclamation-triangle fa-fw mr-1"></i> {{ loadingErr }}</b-alert>
        <div v-else>
            <b-form v-if="showSearchOptions" class="mb-5" @submit.prevent="load">
                <b-form-group label="Filter Systems:" class="mb-4" v-if="false">
<!--                    todo this doesn't work-->
                    <b-form-checkbox v-model="searchSystemsAll" @change="searchSystemsToggleAll">
                        {{ searchSystemsAll ? 'Unselect All' : 'Select All' }}
                    </b-form-checkbox>

                    <b-form-checkbox-group switches
                        v-model="searchSystems"
                        :options="searchSystemsOptions"
                        name="searchSystemsOptions"
                        :disabled="loading"
                    ></b-form-checkbox-group>
                </b-form-group>

                <b-form-group label="Showing Results Between:" description="Select dates to search from.">
                    <b-input-group>
                        <b-form-datepicker v-model="searchDateFrom" size="sm" button-variant="primary" nav-button-variant="primary" :disabled="loading"></b-form-datepicker>
                        <b-btn size="sm" variant="primary" disabled>&raquo;</b-btn>
                        <b-form-datepicker v-model="searchDateTo" size="sm" button-variant="primary" nav-button-variant="primary" :disabled="loading"></b-form-datepicker>
                    </b-input-group>
                </b-form-group>

                <b-btn variant="primary" block type="submit"><i class="fas fa-search fa-fw mr-1"></i> Search</b-btn>
            </b-form>

            <div class="row font-italic small mb-2">
                <div class="col">
                    Showing {{ history.length }} records from {{ formatDate(searchDateFrom) }} to {{ formatDate(searchDateTo) }}.
                </div>
            </div>

            <b-table hover striped :items="history" :fields="columns" show-empty>
                <template v-slot:empty="scope">
                    <div class="text-center">
                        No History Found.
                    </div>
                </template>

                <template v-slot:cell(system)="row">
                    {{ systemName(row.item.system) }}
                </template>

                <template v-slot:cell(state)="row">
                    <b-badge class="stateBadge" :variant="stateBadgeStyle(row.item.state)">{{ row.item.state }}</b-badge>
                </template>

                <template v-slot:cell(oldState)="row">
                    <b-badge class="stateBadge" :variant="stateBadgeStyle(row.item.oldState)">{{ row.item.oldState }}</b-badge>
                </template>

                <template v-slot:cell(checked)="row">
                    {{ formatTime(row.item.checked) }}
                </template>

                <template v-slot:cell(recordCreated)="row">
                    {{ formatTime(row.item.recordCreated) }}
                </template>

                <template v-slot:cell(recordChanged)="row">
                    {{ formatTime(row.item.recordChanged) }}
                </template>
            </b-table>
        </div>
    </div>
</template>


<script>
import {isArray} from "lodash";
import dayjs from "dayjs";

export default {
    name: "SystemStatusHistory",

    created() {
        this.Util.setTitle("System History");
        this.load();
    },

    data() {
        return {
            loading: false,
            hasErr: false,
            loadingErr: "There was an error loading system status history.",

            showSearchOptions: false,
            searchDateFrom: dayjs().subtract(90, 'day').format('YYYY-MM-DD'),
            searchDateTo: dayjs().add(1, 'day').format('YYYY-MM-DD'),
            searchSystems: ['FTP_BLUE', 'FTP_RED', 'PORTAL_BLUE', 'PORTAL_RED', 'CITRIX_BLUE', 'CITRIX_RED', 'VPN_BLUE', 'VPN_RED', 'STAT_BLUE', 'STAT_RED', 'ONBOARDING', 'BDAM'],
            searchSystemsAll: true,
            searchSystemsOptions: [
                { value: 'FTP_BLUE', text: 'Secure FTP (Blue)' },
                { value: 'FTP_RED', text: 'Secure FTP (Red)' },
                { value: 'PORTAL_BLUE', text: 'Reg Portal (Blue)' },
                { value: 'PORTAL_RED', text: 'Reg Portal (Red)' },
                { value: 'CITRIX_BLUE', text: 'Citrix (Blue)' },
                { value: 'CITRIX_RED', text: 'Citrix (Red)' },
                { value: 'VPN_BLUE', text: 'Cisco VPN (Blue)' },
                { value: 'VPN_RED', text: 'Cisco VPN (Red)' },
                { value: 'STAT_BLUE', text: 'STAT (Blue)' },
                { value: 'STAT_RED', text: 'STAT (Red)' },
                { value: 'ONBOARDING', text: 'Onboarding' },
                { value: 'BDAM', text: 'BDAM' },
            ],

            history: [],

            columns: [
                { key: 'checked', sortable: true, },
                { key: 'system', sortable: true, },
                { key: 'oldState', sortable: true, },
                { key: 'state', sortable: true, },
                // { key: 'deleted', sortable: true, },
                // { key: 'recordCreated', label: 'Created', sortable: true, },
                // { key: 'recordChanged', label: 'Modified', sortable: true, },
            ],

            systemNameMap: {
                'FTP_BLUE': 'Secure FTP (Blue)',
                'FTP_RED': 'Secure FTP (Red)',
                'PORTAL_BLUE': 'Reg Portal (Blue)',
                'PORTAL_RED': 'Reg Portal (Red)',
                'CITRIX_BLUE': 'Citrix (Blue)',
                'CITRIX_RED': 'Citrix (Red)',
                'VPN_BLUE': 'Cisco VPN (Blue)',
                'VPN_RED': 'Cisco VPN (Red)',
                'STAT_BLUE': 'STAT (Blue)',
                'STAT_RED': 'STAT (Red)',
                'ONBOARDING': 'Onboarding',
                'BDAM': 'BDAM',
            },

            stateOptions: [ 'Online', 'Offline', 'Maintenance', 'Degraded' ],
            stateStyleMap: {
                'Online': 'success',
                'Offline': 'danger',
                'Maintenance': 'warning',
                'Degraded': 'warning',
            },
        }
    },
    watch: {
        searchSystems(newVal) {
            if (newVal.length === 0) {
                this.searchSystemsAll = false
            } else if (newVal.length === this.searchSystemsOptions.length) {
                this.searchSystemsAll = true
            } else {
                this.searchSystemsAll = false
            }
        }
    },


    methods: {
        systemName(system) {
            return this.systemNameMap.hasOwnProperty(system) ? this.systemNameMap[system] : system;
        },
        stateBadgeStyle(state) {
            return this.stateStyleMap.hasOwnProperty(state) ? this.stateStyleMap[state] : state;
        },
        formatDate(time) {
            return dayjs(time).format('MMM D, YYYY')
        },
        formatTime(time) {
            return dayjs(time).format('ddd MMM D, YYYY h:mm A')
        },
        searchSystemsToggleAll(checked) {
            this.searchSystems = checked ? ['FTP_BLUE', 'FTP_RED', 'PORTAL_BLUE', 'PORTAL_RED', 'CITRIX_BLUE', 'CITRIX_RED', 'VPN_BLUE', 'VPN_RED', 'STAT_BLUE', 'STAT_RED', 'ONBOARDING', 'BDAM'] : [];
        },

        load() {
            if (this.loading === true) return;

            this.loading = true;
            this.hasErr = false;
            this.history = [];

            const opts = {
                params: {
                    from: this.searchDateFrom,
                    to: this.searchDateTo,
                    // system: this.searchSystems.length === this.searchSystemsOptions.length || this.searchSystems.length < 1? null : this.searchSystems,
                }
            };

            this.$http.get('/api/system/history', opts).then((data) => {
                if (data.data && isArray(data.data)) {
                    this.history = data.data;
                } else {
                    this.hasErr = true;
                }
            })
            .catch((error) => {
                this.hasErr = true;
                this.searchSystems = [];
            }).then(() => {
                this.loading = false;
            });
        },
    },
}
</script>
