<template>
    <div class="container">
        <div class="d-flex align-items-center justify-content-between pb-2 border-bottom mb-3">
            <h2>Messages</h2>
            <b-button-group>
                <b-btn :variant="showNewMsgButtonStyle" :disabled="locked" @click="showNewMsg = !showNewMsg"><i class="fas fa-plus"></i> New</b-btn>
                <b-btn variant="outline-success" :disabled="locked" @click="load"><i class="fas fa-sync-alt"></i> Refresh</b-btn>
            </b-button-group>
        </div>

        <div v-if="loading" class="text-center">
            <h3><b-spinner type="grow" variant="success" label="Loading..." /> Loading...</h3>
        </div>
        <b-alert v-else-if="hasErr" variant="danger" :show="hasErr"><i class="fas fa-exclamation-triangle fa-fw mr-1"></i> {{ loadingErr }}</b-alert>
        <div v-else>
            <message-editor v-if="showNewMsg" class="border-bottom mb-5" @update="load" @modifying="childModifying" :modifying="modifying"></message-editor>

            <div class="messages">
                <div v-if="messages.length < 1">
                    <div class="jumbotron jumbotron-fluid">
                        <div class="container lead text-center">
                            No Messages Found. Click on the
                            <b-btn class="ml-1" :variant="showNewMsgButtonStyle" :disabled="locked" @click="showNewMsg = !showNewMsg"><i class="fas fa-plus"></i> New</b-btn>
                            button to create a message.
                        </div>
                    </div>
                </div>
                <div v-else>
                    <h3>Active Messages</h3>
                    <message-record-admin v-for="msg in messages" :key="msg.id" :msg="msg" @update="load" @modifying="childModifying" :modifying="modifying"></message-record-admin>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import MessageEditor from "@/components/Messages/MessageEditor";
import MessageRecordAdmin from "@/components/Messages/MessageRecordAdmin";

import { isArray } from 'lodash'

export default {
    name: "MessageAdministration",
    components: {
        MessageEditor,
        MessageRecordAdmin
    },
    data() {
        return {
            loading: false,
            hasErr: false,
            loadingErr: "There was an error loading messages.",

            showNewMsg: false,
            messages: [],

            modifying: false,
        }
    },
    computed: {
        showNewMsgButtonStyle() {
            return this.showNewMsg ? 'success' : 'outline-success';
        },

        locked() {
            return this.loading || this.modifying;
        }

    },

    created() {
        this.Util.setTitle("Message Admin");
        this.load();
    },

    methods: {
        load() {
            if (this.loading === true) return;

            this.loading = true;
            this.hasErr = false;
            this.showNewMsg = false;

            const url = '/api/messages',
                opts = {
                    params: {
                        active: true,
                    }
                };

            this.$http.get(url, opts).then((data) => {
                if (data.data && isArray(data.data)) {
                    this.messages = data.data;
                } else {
                    this.hasErr = true;
                }
            })
            .catch((error) => {
                this.hasErr = true;
                this.messages = [];
            }).then(() => {
                this.loading = false;
            });
        },

        childModifying(evt) {
            this.modifying = evt && evt.modifying === true;
        }
    }
}
</script>