<template>
    <b-form class="newOverrideEditor" @submit.prevent="saveOverride">
        <h3 class="pb-2 mb-3 border-bottom">{{ editMode ? 'Modify Override' : 'New Override' }}</h3>
        <b-form-group v-if="!editMode" label="Active:" :description="computeDescriptions('active')">
            <b-button-group size="sm">
                <b-btn :variant="activeButtonStyle" @click="active=true" :disabled="locked">
                    <i class="far fa-check-square" v-if="active"></i>
                    <i class="far fa-square" v-else></i>
                    Active
                </b-btn>
                <b-btn :variant="activeDisableButtonStyle" @click="active=false" :disabled="locked">
                    <i class="far fa-check-square" v-if="!active"></i>
                    <i class="far fa-square" v-else></i>
                    Disable
                </b-btn>
            </b-button-group>
            <hr class="col-6">
        </b-form-group>


        <b-form-group v-if="!editMode"  label="System:" :description="computeDescriptions('system')">
            <b-button-group block size="sm">
                <b-btn v-for="sys in systemOptions" :key="sys.name" :variant="computeSystemOptionsStyle(sys)" @click.prevent="system = sys.name" :disabled="locked">
                    <i class="far fa-check-square mr-2" v-if="system === sys.name"></i><i class="far fa-square mr-2" v-else></i>{{ sys.value }}
                </b-btn>
            </b-button-group>
            <hr class="col-6">
        </b-form-group>


        <b-form-group label="State:" :description="computeDescriptions('state')">
            <b-button-group block size="sm">
                <b-btn v-for="state in stateOptions" :key="state" :variant="computeStateOptionStyle(state)" @click.prevent="overrideState = state" :disabled="locked">
                    <i class="far fa-check-square mr-2" v-if="overrideState === state"></i><i class="far fa-square mr-2" v-else></i>{{ state }}
                </b-btn>
            </b-button-group>
        </b-form-group>
        <hr class="col-6">

        <b-form-group label="Enable Override:" :description="computeDescriptions('enable')">
            <b-input-group>
                <b-form-datepicker v-model="enableDate" size="sm" button-variant="primary" nav-button-variant="primary" :disabled="locked"></b-form-datepicker>
                <b-form-timepicker v-model="enableTime" size="sm" minutes-step="15" :disabled="locked"></b-form-timepicker>
            </b-input-group>
        </b-form-group>
        <hr class="col-6">


        <b-form-group label="Disable Override:" :description="computeDescriptions('disable')">
            <b-input-group>
                <b-form-datepicker size="sm" v-model="disableDate" :min="enableDate" :disabled="locked"></b-form-datepicker>
                <b-form-timepicker v-model="disableTime" size="sm" minutes-step="15" :disabled="locked"></b-form-timepicker>
            </b-input-group>
        </b-form-group>
        <hr class="col-6">


        <b-btn variant="primary" block type="submit" :disabled="locked"><i class="far fa-save fa-fw"></i> Save</b-btn>
    </b-form>
</template>


<style >
.newOverrideEditor .form-group > label {
    font-weight: bold;
    padding-left: 3px;
    margin-left: 3px;
}

.newOverrideEditor .form-group > label::before {
    content: "\00BB";
    padding-right: 3px;
}

.newOverrideEditor .form-text {
    outline: none;
}
</style>

<script>
import dayjs from "dayjs";
import { stringify } from 'querystring'

export default {
    name: "OverrideEditor",

    props: {
        override: {
            type: Object,
            required: false,
            default: null,
        }
    },

    computed: {
        editMode() {
            return this.override !== null
        },
        activeButtonStyle() {
            return this.active ? 'primary' : 'outline-primary'
        },
        activeDisableButtonStyle() {
            return !this.active ? 'danger' : 'outline-danger'
        },
    },

    data() {
        return {
            locked: false,

            active: true,
            enableDate: dayjs().format('YYYY-MM-DD'),
            enableTime: dayjs().startOf('hour').format('HH:mm:ss'),
            disableDate: dayjs().format('YYYY-MM-DD'),
            disableTime: dayjs().startOf('hour').add(6, 'hour').format('HH:mm:ss'),
            system: null,
            overrideState: 'Online',

            systemOptions: [
                { name: 'FTP_BLUE', value: 'Secure FTP (Blue)' },
                { name: 'FTP_RED', value: 'Secure FTP (Red)' },
                { name: 'PORTAL_BLUE', value: 'Reg Portal (Blue)' },
                { name: 'PORTAL_RED', value: 'Reg Portal (Red)' },
                { name: 'CITRIX_BLUE', value: 'Citrix (Blue)' },
                { name: 'CITRIX_RED', value: 'Citrix (Red)' },
                { name: 'VPN_BLUE', value: 'Cisco VPN (Blue)' },
                { name: 'VPN_RED', value: 'Cisco VPN (Red)' },
                { name: 'STAT_BLUE', value: 'STAT (Blue)' },
                { name: 'STAT_RED', value: 'STAT (Red)' },
                { name: 'ONBOARDING', value: 'Onboarding' },
                { name: 'BDAM', value: 'BDAM' },
            ],
            stateOptions: [ 'Online', 'Offline', 'Maintenance', 'Degraded' ],
        }
    },

    mounted() {
        if (this.override) {
            this.overrideState = this.override.state;
            this.enableDate = dayjs(this.override.enable).format('YYYY-MM-DD');
            this.enableTime = dayjs(this.override.enable).format('HH:mm:ss');
            this.disableDate = dayjs(this.override.disable).format('YYYY-MM-DD');
            this.disableTime = dayjs(this.override.disable).format('HH:mm:ss');
        }
    },

    methods: {
        computeSystemOptionsStyle(sys) {
            if (sys.name.includes('RED')) {
                return sys.name === this.system ? 'danger' : 'outline-danger';
            }

            return sys.name === this.system ? 'primary' : 'outline-primary';
        },

        computeStateOptionStyle(state) {
            if (state === 'Online') {
                return state === this.overrideState ? 'success' : 'outline-success';
            } else if (state === 'Offline') {
                return state === this.overrideState ? 'danger' : 'outline-danger';
            } else if (state === 'Maintenance' || state === 'Degraded') {
                return state === this.overrideState ? 'warning' : 'outline-warning';
            } else {
                return state === this.overrideState ? 'primary' : 'outline-primary';
            }
        },

        computeDescriptions(label) {
            if (label === 'active') {
                return 'Overrides will only be honored if set to active. This can be changed later.'
            } else if (label === 'system') {
                return 'Choose the system to apply override.';
            } else if (label === 'state') {
                return this.editMode ?
                    'Choose the override state to apply.'
                    : '';
            } else if (label === 'enable') {
                return this.editMode ?
                    'Select date and time of when to enable the override. Defaults to the current hour.'
                    : 'Select date and time of when to enable the override.';
            } else if (label === 'disable') {
                return this.editMode ?
                    'Select date and time of when to disable the override. Defaults to 6 hours from now.'
                    : 'Select date and time of when to disable the override. ';
            }

            return 'Enter Value'
        },

        saveOverride() {
            const notice = (title, msg, variant) => {
                this.$bvToast.toast(msg, {
                    title,
                    variant,
                    toaster: 'b-toaster-top-center',
                    solid: true,
                })
            };


            const error = (msg) => {
                notice('Validation Error', msg, 'danger')
            }

            ///////////////////////////////////////
            // Validate System
            if (!this.editMode && this.system === null) {
                error("You must select a system.");
                return;
            }

            if (!this.editMode && !this.systemOptions.map(value => value.name).includes(this.system)) {
                error("Invalid system selected.");
                return;
            }

            // Validate State
            if (!this.stateOptions.includes(this.overrideState)) {
                error("Invalid override state option.");
                return;
            }

            // Validate Date
            const enableDay = dayjs(this.enableDate + " " + this.enableTime, 'YYYY-MM-DD HH:mm:ss');
            const disableDay = dayjs(this.disableDate + " " + this.disableTime, 'YYYY-MM-DD HH:mm:ss');
            if (enableDay.isAfter(disableDay)) {
                error(`Start time is after disable time. Start time: ${ enableDay.format('ddd MMM DD, YY hh:mm A') }, disable time: ${ disableDay.format('ddd MMM DD, YY hh:mm A') }.`)
                return;
            }

            // If editing, check if things have changed
            if (this.editMode && this.overrideState === this.override.state
                && dayjs(this.override.enable).isSame(enableDay)
                && dayjs(this.override.disable).isSame(disableDay)) {
                    error('No fields changed.');
                    return;
            }

            // Success function
            const successFn = (data) => {
                notice("Override Editor", "Override was saved successfully.", "success")

                setTimeout(() => {
                    this.$emit('update');
                }, 500);
            };

            // Failure function
            const failureFn = (error) => {
                const data = error.response && error.response.data || {};
                const msg = data.hasOwnProperty('msg') ? data.msg : "There was an error saving override.";
                notice("Error Saving", msg, 'danger');
            }

            this.locked = true;
            this.$emit('modifying', { modifying: true });
            if (this.editMode) {
                this.$http.put(`/api/system/override/${ this.override.id }`, stringify({
                    state: this.overrideState,
                    enable: enableDay.toJSON(),
                    disable: disableDay.toJSON(),
                })).then(successFn).catch(failureFn).finally(() => {
                    this.locked = false;
                    this.$emit('modifying', { modifying: false });
                });
            } else {
                this.$http.post('/api/system/override', stringify({
                    system: this.system,
                    state: this.overrideState,
                    active: this.active,
                    enable: enableDay.toJSON(),
                    disable: disableDay.toJSON(),
                })).then(successFn).catch(failureFn).finally(() => {
                    this.locked = false;
                    this.$emit('modifying', { modifying: false });
                });
            }
        },
    },

}
</script>
