<template>
    <b-table id="activeOverrideTable" striped bordered hover :items="overrides" :fields="showAllColumns ? columnsAll : columns" show-empty>
        <template v-slot:empty="scope">
            <div class="text-center">
                No Overrides Found. Click on the <b-btn class="mx-2" variant="outline-success" :disabled="true"><i class="fas fa-plus"></i> New</b-btn> button to create an override.
            </div>
        </template>

        <template v-slot:cell(system)="row">
            <b-dropdown block :variant="showDetailsBtnVariant(row.item)" v-if="editable" size="sm">
                <template #button-content>
                    <strong>{{ systemName(row.item.system) }}</strong>
                </template>

                <b-dropdown-item :disabled="locked" v-if="!row.item.active" @click="activeDeactivateRecord(row.item, true)">
                    <i class="fas fa-exclamation fa-fw mr-1"></i> Enable
                </b-dropdown-item>
                <b-dropdown-item :disabled="locked" v-if="row.item.active" @click="activeDeactivateRecord(row.item, false)">
                    <i class="fas fa-eye-slash fa-fw mr-1"></i> Disable
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>

                <b-dropdown-item :disabled="locked" @click="row.toggleDetails">
                    <template v-if="row.detailsShowing">
                        <i class="far fa-edit fa-fw mr-1"></i> Cancel Modify
                    </template>
                    <template v-else>
                        <i class="far fa-edit fa-fw mr-1"></i> Modify
                    </template>

                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>

                <b-dropdown-item :disabled="locked" v-if="!row.item.deleted" @click="deleteRecord(row.item)">
                    <i class="far fa-trash-alt fa-fw mr-1"></i> Delete
                </b-dropdown-item>
            </b-dropdown>

            <template v-else>
                {{ systemName(row.item.system) }}
            </template>
        </template>

        <template v-slot:cell(state)="row">
            <b-badge class="stateBadge" :variant="stateBadgeStyle(row.item.state)">{{ row.item.state }}</b-badge>
        </template>

        <template v-slot:cell(active)="row">
            <template v-if="row.item.active">
                <i class="fas fa-check fa-fw text-success"></i> Yes
            </template>
            <template v-else>
                <i class="fas fa-times fa-fw text-danger"></i> No
            </template>
        </template>

        <template v-slot:cell(enable)="row">
            {{ formatTime(row.item.enable) }}
            <template v-if="isFutureDate(row.item.enable)">
                <i class="far fa-clock fa-fw text-success"></i>
            </template>
            <template v-else-if="isRecentDate(row.item.enable)">
                <i class="far fa-clock fa-fw text-warning" v-b-tooltip.hover title="Recent date (~48 hours)."></i>
            </template>
        </template>

        <template v-slot:cell(disable)="row">
            {{ formatTime(row.item.disable) }}
            <template v-if="isFutureDate(row.item.disable)">
                <i class="far fa-clock fa-fw text-success" v-b-tooltip.hover title="Future date."></i>
            </template>
            <template v-else-if="isRecentDate(row.item.disable)">
                <i class="far fa-clock fa-fw text-warning" v-b-tooltip.hover title="Recent event (~48 hours.)"></i>
            </template>
        </template>

        <template v-slot:cell(deleted)="row">
            <template v-if="row.item.deleted">
                {{ formatTime(row.item.deleted) }}
            </template>
            <template v-else>
                <i class="fas fa-times fa-fw"></i>
            </template>
        </template>

        <template v-slot:cell(recordCreated)="row">
            {{ formatTime(row.item.recordCreated) }}
        </template>

        <template v-slot:cell(recordChanged)="row">
            {{ formatTime(row.item.recordChanged) }}
            <template v-if="isRecentDate(row.item.recordChanged)">
                <i class="far fa-clock fa-fw text-warning" v-b-tooltip.hover title="Recent event (~48 hours.)"></i>
            </template>
        </template>

        <template v-slot:row-details="row">
            <b-card>
                <override-editor :override="row.item" @modifying="childModifying" @update="childUpdated"></override-editor>
            </b-card>
        </template>
    </b-table>
</template>

<style>
#activeOverrideTable tr.b-table-details {
    outline: none;
}

#activeOverrideTable .editOverrideRowCheckbox label {
    font-size: .9rem;
    font-style: italic;
}
</style>

<script>
import dayjs from "dayjs";
import { stringify } from 'querystring'
import OverrideEditor from "@/components/System/OverrideEditor";

export default {
    name: "OverrideRecordTable",

    components: {
        OverrideEditor
    },

    props: {
        overrides : {
            type: Array,
        },
        loading: {
            type: Boolean
        },
        editable: {
            type: Boolean,
            required: false,
            default: true,
        },
        showAllColumns: {
            type: Boolean,
            required: false,
            default: false,
        }
    },

    computed: {
        locked() {
            return this.loading || this.modifying;
        },
    },

    data() {
        return {
            modifying: false,

            columns: [
                { key: 'system', sortable: true, thClass: 'text-center', },
                { key: 'state', label: 'Override State', sortable: true, thClass: 'text-center', tdClass: 'text-center' },
                { key: 'active', sortable: true, },
                { key: 'enable', sortable: true, },
                { key: 'disable', sortable: true, },
                { key: 'recordChanged', label: 'Modified', sortable: true, },
            ],

            columnsAll: [
                { key: 'system', sortable: true, thClass: 'text-center', },
                { key: 'state', label: 'Override State', sortable: true, thClass: 'text-center', tdClass: 'text-center' },
                { key: 'active', sortable: true, },
                { key: 'enable', sortable: true, },
                { key: 'disable', sortable: true, },
                { key: 'deleted', sortable: true, },
                { key: 'recordCreated', label: 'Created', sortable: true, },
                { key: 'recordChanged', label: 'Modified', sortable: true, },
            ],

            systemNameMap: {
                'FTP_BLUE': 'Secure FTP (Blue)',
                'FTP_RED': 'Secure FTP (Red)',
                'PORTAL_BLUE': 'Reg Portal (Blue)',
                'PORTAL_RED': 'Reg Portal (Red)',
                'CITRIX_BLUE': 'Citrix (Blue)',
                'CITRIX_RED': 'Citrix (Red)',
                'VPN_BLUE': 'Cisco VPN (Blue)',
                'VPN_RED': 'Cisco VPN (Red)',
                'STAT_BLUE': 'STAT (Blue)',
                'STAT_RED': 'STAT (Red)',
                'ONBOARDING': 'Onboarding',
                'BDAM': 'BDAM',
            },

        }
    },


    methods: {
        systemName(system) {
            return this.systemNameMap.hasOwnProperty(system) ? this.systemNameMap[system] : system;
        },
        showDetailsBtnVariant(record) {
            return record.system.toLowerCase().includes('red') ? 'outline-danger' : 'outline-primary';
        },
        stateBadgeStyle(state) {
            const stateStyleMap = {
                    'Online': 'success',
                    'Offline': 'danger',
                    'Maintenance': 'warning',
                    'Degraded': 'warning',
                };

            return stateStyleMap.hasOwnProperty(state) ? stateStyleMap[state] : state;
        },
        formatTime(time) {
            return dayjs(time).format('ddd MMM D, YYYY h:mm A')
        },
        isFutureDate(day) {
            return dayjs(day).isAfter(dayjs());
        },
        isRecentDate(day) {
            return dayjs(day).isAfter(dayjs().subtract(48, 'hour'));
        },

        notice(title, msg, variant) {
            this.$bvToast.toast(msg, {
                title,
                variant,
                toaster: 'b-toaster-top-center',
                solid: true,
            })
        },
        noticeErr(msg) {
            this.notice('Error', msg, 'danger')
        },


        activeDeactivateRecord(rec, active) {
            this.modifying = true;
            this.$http.put(`/api/system/override/${ rec.id }/active`, stringify({ active }))
                .then(() => {
                    this.notice("Success", active ? "Successfully activated override." : "Successfully deactivated override.", 'success')

                    setTimeout(() => {
                        this.$emit('update');
                    }, 500);
                }).catch((error) => {
                    const data = error.response && error.response.data || {};
                    const msg = data.hasOwnProperty('msg') ? data.msg : "There was an error modifying override.";
                    this.noticeErr(msg);
                }).finally(() => {
                    this.modifying = false;
                });
        },

        deleteRecord(rec) {
            this.modifying = true;
            this.$http.delete(`/api/system/override/${ rec.id }`)
                .then(() => {
                    this.notice("Success", "Successfully deleted override record.", 'success')

                    setTimeout(() => {
                        this.$emit('update');
                    }, 500);
                }).catch((error) => {
                    const data = error.response && error.response.data || {};
                    const msg = data.hasOwnProperty('msg') ? data.msg : "There was an error deleting override.";
                    this.noticeErr(msg);
                }).finally(() => {
                    this.modifying = false;
                });
        },


        childModifying(evt) {
            this.modifying = evt && evt.modifying || false;
        },
        childUpdated() {
            this.$emit('update');
        },

    },
}
</script>