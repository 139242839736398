<template>
    <div class="container">
        <div class="d-flex align-items-center justify-content-between pb-2 border-bottom mb-3">
            <h2>Message History</h2>
            <b-button-group>
                <b-btn variant="outline-success" :disabled="loading" @click="load"><i class="fas fa-sync-alt fa-fw mr-1"></i>Refresh</b-btn>

                <b-dropdown variant="outline-success" :disabled="loading" right>
                    <template v-slot:button-content>
                        <i class="fas fa-cog fa-fw mr-1"></i>Config
                    </template>


                    <b-dropdown-form @submit.stop.prevent>
                        <b-form-checkbox v-model="showDeleted" switch class="text-nowrap">Include Deleted</b-form-checkbox>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-form-checkbox v-model="showAll" switch class="text-nowrap">Show All Messages</b-form-checkbox>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-form-group description="Limit Messages Shown">
                            <b-form-input :disabled="showAll" v-model.trim.number="limitInputField" placeholder="Limit Messages" type="number"></b-form-input>
                        </b-form-group>
                    </b-dropdown-form>
                </b-dropdown>
            </b-button-group>
        </div>

        <div v-if="loading" class="text-center">
            <h3><b-spinner type="grow" variant="success" label="Loading..." /> Loading...</h3>
        </div>
        <b-alert v-else-if="hasErr" variant="danger" :show="hasErr"><i class="fas fa-exclamation-triangle fa-fw mr-1"></i> {{ loadingErr }}</b-alert>
        <div v-else-if="messages.length > 0">
            <div class="mb-3 text-right text-muted font-italic small">
                Showing {{ showAll ? 'all' : limit + ' most recently modified' }} messages{{ showDeleted ? ', including deleted' : '' }}.
            </div>
            <message-record-admin v-for="msg in messages" :key="msg.id" :msg="msg" :editable="false"></message-record-admin>
        </div>
        <div v-else>
            <div class="jumbotron jumbotron-fluid">
                <div class="container lead text-center">
                    No Messages Found.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MessageRecordAdmin from "@/components/Messages/MessageRecordAdmin";
import { isArray, debounce } from 'lodash'

export default {
    name: "MessageHistory",
    components: {
        MessageRecordAdmin
    },
    data() {
        return {
            loading: false,
            hasErr: false,
            loadingErr: "There was an error loading messages.",

            messages: [],

            showDeleted: false,
            showAll: false,
            limit: 50,
            limitInputField: 50,
        }
    },

    watch: {
        showDeleted() { this.load(); },
        showAll() { this.load(); },
        limitInputField: debounce(function() {
            if (this.limitInputField !== this.limit) {
                this.limit = this.limitInputField;
                this.load();
            }
        }, 2500),
    },

    created() {
        this.Util.setTitle("Message History");
        this.load();
    },

    methods: {
        load() {
            if (this.loading === true) return;
            this.loading = true;
            this.hasErr = false;

            const opts = {
                    params: {
                        active: false,
                        deleted: this.showDeleted,
                        all: this.showAll,
                        limit: this.limit,
                    }
                };

            this.$http.get('/api/messages', opts)
            .then((data) => {
                if (data.data && isArray(data.data)) {
                    this.messages = data.data;
                } else {
                    this.hasErr = true;
                }
            })
            .catch((error) => {
                this.hasErr = true;
                this.messages = [];
            }).then(() => {
                this.loading = false;
            });
        },
    }
}
</script>