<template>
    <div class="container">
        <h2>Banners</h2>
        <b-alert variant="danger" show><h4 class="m-0"><i class="far fa-clock fw-fw mr-2"></i> Coming Soon</h4></b-alert>


    </div>
</template>

<style scoped>

</style>

<script>

export default {
    name: "BannerAdmin",
    components: {
    },

    created() {
        this.Util.setTitle("Banner Manager");
        this.load();
    },

    methods: {
        load() {

        },
    }
}

</script>