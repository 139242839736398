<template>
    <div class="container-fluid">
        <div class="d-flex align-items-center justify-content-between pb-2 border-bottom mb-3">
            <h2>Systems Manager</h2>
            <b-button-group>
                <b-btn :variant="showNewOverrideButtonStyle" :disabled="loading" @click="showNewOverride = !showNewOverride"><i class="fas fa-plus fa-fw"></i> New</b-btn>
                <b-btn variant="outline-success" :disabled="loading" @click="forceUpdateState"><i class="fas fa-sync-alt fa-fw"></i> Force Update Status</b-btn>
                <b-btn variant="outline-success" :disabled="loading" @click="load"><i class="fas fa-sync-alt fa-fw"></i> Refresh</b-btn>
            </b-button-group>
        </div>

        <div v-if="loading" class="text-center">
            <h3><b-spinner type="grow" variant="success" label="Loading..." /> Loading...</h3>
        </div>
        <b-alert v-else-if="hasErr" variant="danger" :show="hasErr"><i class="fas fa-exclamation-triangle fa-fw mr-1"></i> {{ loadingErr }}</b-alert>
        <div v-else>
            <override-editor v-if="showNewOverride" class="container pb-3 border-bottom" style="margin-bottom: 5rem;" @update="load"></override-editor>

            <h3>Active/Upcoming Override</h3>
            <override-table :loading="loading" :overrides="overrides" @update="load"></override-table>
        </div>
    </div>
</template>

<script>
import OverrideEditor from "@/components/System/OverrideEditor";
import OverrideTable from "@/components/System/OverrideRecordsTable";
import { isArray } from "lodash";

export default {
    name: "SystemAdmin",

    components: {
        OverrideEditor,
        OverrideTable
    },

    data() {
        return {
            loading: false,
            hasErr: false,
            loadingErr: "There was an error loading currently active configuration.",

            showNewOverride: false,
            overrides: [],
        }
    },

    computed: {
        showNewOverrideButtonStyle() {
            return this.showNewOverride ? 'success' : 'outline-success';
        },
    },

    created() {
        this.Util.setTitle("Systems Manager");
        this.load();
    },

    methods: {
        load() {
            if (this.loading === true) return;

            this.loading = true;
            this.hasErr = false;
            this.showNewOverride = false;
            this.overrides = [];

            const url = '/api/system/override',
                opts = {
                    params: {
                        active: true,
                    }
                };

            this.$http.get(url, opts).then((data) => {
                if (data.data && isArray(data.data)) {
                    this.overrides = data.data;
                } else {
                    this.hasErr = true;
                }
            })
            .catch((error) => {
                this.hasErr = true;
            }).then(() => {
                this.loading = false;
            });
        },

        forceUpdateState() {
            this.loading = true;

            this.$http.post('/api/system/force-update')
            .then(() => {
                this.$bvToast.toast("Successfully update status.", {
                    title: "Status",
                    variant: 'success',
                    toaster: 'b-toaster-top-center',
                    solid: true,
                });
            })
            .catch(() => {
                this.$bvToast.toast("Failed to update status.", {
                    title: "Status",
                    variant: 'danger',
                    toaster: 'b-toaster-top-center',
                    solid: true,
                })
            }).then(() => {
                this.loading = false;
            });

        },
    }
}
</script>
