<template>
    <div class="container-fluid">
        <div class="d-flex align-items-center justify-content-between pb-2 border-bottom mb-3">
            <h2>Override History</h2>

            <b-button-group>
                <b-btn variant="outline-success" :disabled="loading" @click="load"><i class="fas fa-sync-alt fa-fw"></i> Refresh</b-btn>

                <b-dropdown variant="outline-success" :disabled="loading" right>
                    <template v-slot:button-content>
                        <i class="fas fa-cog fa-fw mr-1"></i>Config
                    </template>

                    <b-dropdown-form @submit.stop.prevent>
                        <b-form-checkbox v-model="showDeleted" switch class="text-nowrap">Include Deleted</b-form-checkbox>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-form-checkbox v-model="showAll" switch class="text-nowrap">Show All Messages</b-form-checkbox>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-form-group description="Limit Messages Shown">
                            <b-form-input :disabled="showAll" v-model.trim.number="limitInputField" placeholder="Limit Messages" type="number"></b-form-input>
                        </b-form-group>
                    </b-dropdown-form>
                </b-dropdown>
            </b-button-group>
        </div>

        <div v-if="loading" class="text-center">
            <h3><b-spinner type="grow" variant="success" label="Loading..." /> Loading...</h3>
        </div>
        <b-alert v-else-if="hasErr" variant="danger" :show="hasErr"><i class="fas fa-exclamation-triangle fa-fw mr-1"></i> {{ loadingErr }}</b-alert>
        <div v-else>
            <override-record :loading="loading" :overrides="history" :show-all-columns="true" @update="load"></override-record>
        </div>
    </div>
</template>

<script>
import OverrideRecord from "@/components/System/OverrideRecordsTable";
import {debounce, isArray} from "lodash";

export default {
    name: "OverrideHistory",

    components: {
        OverrideRecord
    },

    mounted() {
        this.Util.setTitle("Override History");
        this.load();
    },

    data() {
        return {
            loading: false,
            hasErr: false,
            loadingErr: "There was an error loading history.",

            history: [],

            showDeleted: false,
            showAll: false,
            limit: 50,
            limitInputField: 50,
        }
    },

    watch: {
        showDeleted() { this.load(); },
        showAll() { this.load(); },
        limitInputField: debounce(function() {
            if (this.limitInputField !== this.limit) {
                this.limit = this.limitInputField;
                this.load();
            }
        }, 2500),
    },

    methods: {
        load() {
            if (this.loading === true) return;

            this.loading = true;
            this.hasErr = false;
            this.history = [];

            const opts = {
                params: {
                    active: false,
                    deleted: this.showDeleted,
                    all: this.showAll,
                    limit: this.limit,
                }
            };

            this.$http.get('/api/system/override', opts)
            .then((data) => {
                if (data.data && isArray(data.data)) {
                    this.history = data.data;
                } else {
                    this.hasErr = true;
                }
            })
            .catch((error) => {
                this.hasErr = true;
            }).then(() => {
                this.loading = false;
            });
        }
    }
}
</script>
